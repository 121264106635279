<template>
    <NebulaPopover
        class="account-popover"
        ariaLabel="My Account Popover"
        id="accountPopover"
        placement="bottom"
        ref="accountPopover"
    >
        <template v-slot:content>
            <div v-if="!loading" class="account-popover__body">
                <div class="account-popover__content-container">
                    <ProfileAvatar
                        class="account-popover__content-avatar"
                        :image="profileAvatarUrl"
                        :name="displayName"
                        size="lg"
                        :icon="avatarIcon"
                    />
                    <a :href="profileRoute()" class="account-popover__content-name">{{ displayName }}</a>
                    <div v-if="badge" class="account-popover__content-badge-container">
                        <NebulaBadge
                            class="account-popover__content-badge"
                            size="s"
                            :text="badge"
                            type="outline"
                        />
                    </div>
                    <div class="account-popover__content-meta-container">
                        <a v-if="appMode === EMPLOYEE_ROLE" :href="orgProfileUrl()" class="account-popover__content-meta-item">
                            <ProfileAvatar
                                class="account-popover__content-meta-avatar"
                                :image="organization.avatarUrl"
                                :name="organization.name"
                                size="xs"
                                icon="user--filled"
                            />
                            <span class="account-popover__content-meta-name">{{ organization.name }}</span>
                        </a>
                        <div v-if="appMode === ORGADMIN_ROLE" class="account-popover__content-meta-item">
                            <NebulaIcon
                                symbol-id="pin"
                                size="s"
                                class="account-popover__content-meta-icon"
                            />
                            <span class="account-popover__content-meta-name">{{ getLocation() }}</span>
                        </div>
                        <div v-if="appMode === EDUCATOR_ROLE">
                            <span class="account-popover__content-meta-name">{{ siteName }}</span>
                        </div>
                    </div>
                </div>

                <div class="account-popover__button-container" v-if="!inError">
                    <NebulaButton
                        class="account-popover__button"
                        :aria-label="$t(dashboardText)"
                        :text="$t(dashboardText)"
                        type="ghost"
                        routerLink
                        link="/"
                    />
                </div>

                <div
                    v-if="profiles && profiles.length > 0"
                    class="account-popover__section-container"
                >
                    <h3 class="account-popover__section-title">{{ profilesTitle }}</h3>
                    <ul class="account-popover__switch-list">
                        <li
                            v-for="(profile, idx) in profiles"
                            :key="idx"
                            class="account-popover__switch-item"
                        >
                            <button class="account-popover__switch-button" @click="profile.action(profile)">
                                <ProfileAvatar
                                    class="account-popover__switch-avatar"
                                    :image="profile.avatarUrl"
                                    :name="profile.displayName"
                                    size="xs"
                                    :icon="profile.icon"
                                />
                                <span class="account-popover__switch-name">{{ profile.displayName }}</span>
                            </button>
                        </li>
                    </ul>
                </div>

                <div
                    v-if="accountLinks && accountLinks.length > 0 && !inError"
                    class="account-popover__section-container"
                >
                    <h3 class="account-popover__section-title">{{ accountLinksTitle }}</h3>
                    <ul class="account-popover__account-list">
                        <li
                            v-for="link in accountLinks"
                            :key="link.id"
                            class="account-popover__account-item"
                        >
                            <a
                                class="account-popover__account-link"
                                :href="link.url"
                                v-bind="{
                                    'aria-label': link.label,
                                    target: link.target,
                                }"
                            >{{ link.text }}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="account-popover__footer-container">
                <NebulaButton
                    class="account-popover__footer-button"
                    icon-left="log-out"
                    id="popover-accounts"
                    type="flat"
                    @click="handleSignOut"
                    :text="$t('Sign Out')"
                />
            </div>
        </template>
    </NebulaPopover>
</template>

<script>
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';
import { getAtlasPageIdFromOrgId } from '@/utils/data/resources';
import {
    NebulaBadge, NebulaButton, NebulaIcon, NebulaPopover,
} from '@discoveryedu/nebula-components';

import {
    ADMIN_ROLES,
    MEMBER_ROLES,
    ROLE_DISPLAY_MAP,
    roles,
} from '@/constants';
import { setCookie } from '@/utils/cookies';

const envMap = {
    development: '.dev',
    qa: '.qa',
    staging: '.stage',
    production: '',
};

export default {
    name: 'AccountPopover',
    components: {
        NebulaBadge,
        NebulaButton,
        NebulaIcon,
        NebulaPopover,
        ProfileAvatar,
    },
    computed: {
        flags() {
            return this.$store.getters.flags;
        },
        appMode() {
            return this.$store.state.app.mode;
        },
        avatarIcon() {
            let icon = 'user--filled';

            if (this.appMode === roles.ORGADMIN_ROLE) {
                icon = 'buildings';
            }

            return icon;
        },
        profileAvatarUrl() {
            if (ADMIN_ROLES.includes(this.appMode)) {
                return this.organization.avatarUrl;
            }
            return this.member.avatarUrl;
        },
        siteName() {
            return this.$store.state.user.data?.site?.site_name || '';
        },
        displayName() {
            const { appMode } = this;

            const { preferredName, firstName, lastName } = this.member;
            const { name: orgName } = this.organization;

            const isMember = MEMBER_ROLES.includes(appMode);

            // handle inactive displays
            if (isMember && !this.isActive) {
                return 'Name';
            }
            if (ADMIN_ROLES.includes(appMode) && !this.organization.isActive) {
                const orgTypePlaceholder = {
                    [roles.ORGADMIN_ROLE]: 'Organization',
                };

                return orgTypePlaceholder[appMode];
            }

            return isMember ? `${preferredName || firstName} ${lastName}` : orgName;
        },
        badge() {
            const { appMode } = this;
            return ROLE_DISPLAY_MAP[appMode];
        },
        hasAdmin() {
            return this.$store.state.user.hasAdmin;
        },
        profiles() {
            const toReturn = [];
            if (this.appMode === roles.ORGADMIN_ROLE) {
                const displayFirstName = this.member.preferredName || this.member.firstName;
                let name;

                // if fields haven't been populated, just display fallback text
                if (!displayFirstName) {
                    name = 'Personal Profile';
                } else {
                    name = `${displayFirstName} ${this.member.lastName}`;
                }

                const { avatarUrl, isActive } = this.member;
                toReturn.push({
                    displayName: name,
                    avatarUrl,
                    isActive,
                    icon: 'user--filled',
                    action: this.switchToMember,
                });
            }
            if (this.appMode === roles.EMPLOYEE_ROLE) {
                if (this.hasAdmin) {
                    const { name, avatarUrl, isActive } = this.organization;
                    toReturn.push({
                        displayName: name,
                        avatarUrl,
                        isActive,
                        icon: 'buildings',
                        action: this.switchToOrgAdminMode,
                    });
                }
            }

            return toReturn;
        },
        accountLinks() {
            const links = [];

            if (this.appMode === roles.EMPLOYEE_ROLE) {
                links.push(
                    {
                        text: 'Edit Profile',
                        url: '/profile-setup',
                    },
                );
            }

            if (this.appMode === roles.EDUCATOR_ROLE) {
                if (!this.flags['educator-setup-redesign']) {
                    links.push(
                        {
                            text: 'Edit Profile',
                            url: '/profile-setup',
                        },
                    );
                } else {
                    const env = process.env.NODE_ENV;
                    const envString = envMap[env.toLowerCase()];

                    links.push(
                        {
                            text: 'Edit Profile',
                            url: `https://app${envString}.discoveryeducation.com/learn/profile/personalization`,
                            target: '_blank',
                            label: 'Edit Profile, opens in new tab in Discovery Education',
                        },
                    );
                }
            }

            if (ADMIN_ROLES.includes(this.appMode)) {
                links.push(
                    {
                        text: 'User Management',
                        url: '/user-management',
                    },
                );
            }

            let helpAndSupportLink;
            if (this.appMode === roles.EDUCATOR_ROLE) {
                helpAndSupportLink = this.$t('help-link-educators', { ns: 'help' });
            } else if (this.appMode === roles.EMPLOYEE_ROLE) {
                helpAndSupportLink = this.$t('help-link-employees', { ns: 'help' });
            }

            if (MEMBER_ROLES.includes(this.appMode)) {
                links.push(
                    {
                        text: this.$t('help-link-text', { ns: 'help' }),
                        url: helpAndSupportLink,
                        target: '_blank',
                        label: 'Help, opens in new tab',
                    },
                );
            }

            return links;
        },
        isActive() {
            return !!this.$store.state.app.active;
        },
        inError() {
            // sometimes popover can appear in error state
            // this will allow us to allow users to log out
            return !!this.$store.state.app.error;
        },
    },
    methods: {
        triggerAccountPopover() {
            this.$refs.accountPopover.togglePopover();
        },
        handleSignOut() {
            if (this.$auth.isAuthenticated) {
                this.$auth.logout({
                    logoutParams: {
                        returnTo: window.location.origin,
                    },
                });
            }
            if (this.$de.isAuthenticated) {
                this.$de.logout();
            }
        },
        getLocation() {
            return `${this.organization.city}, ${this.organization.state}`;
        },
        orgProfileUrl() {
            let url;
            if (this.appMode === roles.EMPLOYEE_ROLE) {
                const { organizationId } = this.$store.state.app.organization;
                const atlasPageId = getAtlasPageIdFromOrgId(organizationId);
                if (atlasPageId && this.$store.state.app.flags['atlas-content']) {
                    url = `https://careerconnect.discoveryeducation.com/pages/${atlasPageId}`;
                } else {
                    url = `/organization/${organizationId}`;
                }
            }
            return url;
        },
        profileRoute() {
            if (this.appMode === roles.ORGADMIN_ROLE) {
                return this.orgProfileUrl();
            }

            return null;
        },
        async switchToMember(profile) {
            const { name: routeName } = this.$route;
            setCookie('careers-app-mode', roles.EMPLOYEE_ROLE, 60);

            if (profile.isActive) {
                // if member is active, route to dashboard on mode switch
                return routeName === 'dashboard' ? window.location.reload() : this.$router.push('/');
            }
            // other routing will be handled in the routing guide
            return window.location.reload();
        },
        async switchToOrgAdminMode(profile) {
            const { name: routeName } = this.$route;

            setCookie('careers-app-mode', roles.ORGADMIN_ROLE, 60);
            if (profile.isActive) {
                // if org is active, route to dashboard on mode switch
                return routeName === 'dashboard' ? window.location.reload() : this.$router.push('/');
            }
            // other routing will be handled in the routing guide
            return window.location.reload();
        },
    },
    data() {
        return {
            EDUCATOR_ROLE: roles.EDUCATOR_ROLE,
            EMPLOYEE_ROLE: roles.EMPLOYEE_ROLE,
            ORGADMIN_ROLE: roles.ORGADMIN_ROLE,
            profilesTitle: 'Switch Profile',
            accountLinksTitle: 'Account',
            exit: 'Sign Out',
            dashboardText: 'View Dashboard',
            display: {
                avatarUrl: '',
                name: '',
                badge: '',
            },
        };
    },
    props: {
        showAccountPopover: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
        },
        organization: {
            type: Object,
        },
        member: {
            type: Object,
        },
    },
};
</script>

<style lang='stylus'>
:root {
    --careers-popover-width: 312px;
}

.account-popover {
    .nebula-popover {
        left: auto !important;
        max-width: var(--careers-popover-width);
        min-width: var(--careers-popover-width);
        right: -100vw;
        width: 100%;

        &.popper {
            &[x-placement^=bottom] {
                margin-top: $nebula-space-1x;

                .popper__arrow {
                    left: auto !important;
                    right: $nebula-space-4x;
                    height: $nebula-space-1x + $nebula-space-half;
                    top: -($nebula-space-1x - 1);
                    width: $nebula-space-1x + $nebula-space-half;
                }
            }

            .nebula-popover__close-button.nebula-button {
                .nebula-button__icon {
                    height: (($nebula-space-1x * 2) + $nebula-space-half);
                    width: (($nebula-space-1x * 2) + $nebula-space-half);
                }
            }
        }
    }

    &__switch-button {
        border: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: transparent;
        padding: 0;
        transition: $nebula-transition-default;
        &:hover {
            text-decoration: underline;
        }
    }

    &__switch-avatar {
        margin-inline-end: $nebula-space-1x;
    }

    &__content {
        &-container {
            align-items: center;
            display: flex;
            flex-direction: column;
        }

        &-name {
            color: $nebula-color-platform-interactive-800;
            margin-top: $nebula-space-2x;
        }

        &-badge {
            &-container {
                margin-top: $nebula-space-1x;
            }

            .nebula-badge {
                color: $nebula-color-platform-interface-1000;
                border-color: $nebula-color-platform-interface-500;
            }
        }

        &-meta {
            &-container {
                align-items: center;
                display: flex;
                flex-direction: column;
                margin-top: $nebula-space-2x;
            }

            &-avatar {
                &.profile-avatar {
                    --careers-avatar-size: (($nebula-space-1x * 2) + $nebula-space-half);
                }
            }

            &-item {
                align-items: center;
                color: $nebula-color-platform-neutral-700;
                display: flex;
                font-size: $nebula-font-size-caption;
                gap: $nebula-space-1x;

                &[href] {
                    font-weight: 600;
                }
            }

            &-icon {
                align-items: center;
                display: flex;
                fill: $nebula-color-platform-neutral-700;
                height: ($nebula-space-1x + $nebula-space-half);
                width: ($nebula-space-1x + $nebula-space-half);
            }
        }
    }

    &__button {
        flex: 1;
        justify-content: center;

        &-container {
            display: flex;
            margin: $nebula-space-2x auto $nebula-space-4x;
            width: 100%;
        }
    }

    &__section {
        &-container {
            border-bottom: 1px solid $nebula-color-platform-interface-200;
            margin-bottom: $nebula-space-2x;
            padding-bottom: $nebula-space-2x;
        }

        &-title {
            color: $nebula-color-platform-neutral-900;
            font-size: $nebula-font-size-body-1;
            font-weight: 700;
            margin: 0 0 $nebula-space-1x;
        }
    }

    &__switch {
        &-list {
            display: flex;
            flex-direction: column;
            gap: $nebula-space-2x;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        &-link {
            align-items: center;
            color: $nebula-color-platform-neutral-900;
            display: flex;
            gap: $nebula-space-1x;
        }
    }

    &__account {
        &-list {
            display: flex;
            flex-direction: column;
            gap: $nebula-space-half;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        &-link {
            color: $nebula-color-platform-interactive-800;
            font-size: $nebula-font-size-caption;
        }
    }

    &__footer {
        &-container {
            display: flex;
        }

        &-button {
            flex: 1;
            justify-content: center;
        }
    }
}
</style>
