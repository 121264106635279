<template>
    <component
        :is="orgComponent"
        v-bind="orgComponent === 'router-link' ? { to: link } : orgComponent === 'a' ? { href: link } : {}"
        :class="['organization-name', {
            'organization-name--heavy': heavy,
        }]"
    >
        {{ organization.name }}
    </component>
</template>

<script>
import { educatorRoles } from '@/constants';
import { getAtlasPageIdFromOrgId } from '@/utils/data/resources';

export default {
    name: 'OrgName',
    props: {
        organization: Object,
        heavy: Boolean,
        isLink: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        orgComponent() {
            if (this.isMember && this.isLink) {
                const atlasPageId = getAtlasPageIdFromOrgId(this.orgId);
                if (this.$store.state.app.flags['atlas-content'] && atlasPageId) {
                    return 'a';
                }
                return 'router-link';
            }
            return 'div';
        },
        orgId() {
            const { organization } = this;
            return organization.organizationId || organization.organization_id;
        },
        isMember() {
            if (educatorRoles.includes(this.$store.getters.appMode)) {
                return true;
            }
            const orgIds = this.$store.getters.userOrganizations.map((each) => each.organizationId);

            return !!orgIds.includes(this.orgId);
        },
        link() {
            const atlasPageId = getAtlasPageIdFromOrgId(this.orgId);
            if (this.isMember && this.isLink && this.$store.state.app.flags['atlas-content'] && atlasPageId) {
                return `https://careerconnect.discoveryeducation.com/pages/${atlasPageId}`;
            } if (this.isMember && this.isLink) {
                return `/organization/${this.orgId}`;
            }
            return '';
        },
    },
};
</script>

<style lang="stylus">
.organization-name {
    color: $nebula-color-platform-neutral-700;
    font-size: $nebula-font-size-caption;

    &--heavy {
        font-weight: 600;
    }
    &--light {
        font-weight: 400;
    }
}
</style>
