<template>
    <GrayBoxLoader class="notification-settings__loader" v-if="loading" />
    <div v-else class="notification-settings">
        <SettingsSection
            title="Email Notifications"
            description="Select when you'll be notified when the following changes occur."
        >
            <SettingRow
                right
                :data="{
                    setting: emailSettingAll,
                    destination: 'email',
                    type: ['all'],
                    display: $t('select-all', { ns: 'settings' })
                }"
                @setting-changed="toggleSetting"
            />
            <div
                v-for="(each, idx) in settings.email" :key="`setting-email-${idx}`"
            >
                <SettingRow :data="{ ...each, destination: 'email' }" @setting-changed="toggleSetting" />
            </div>
            <div
                v-for="(each, idx) in simpleEmailSettings" :key="`simple-setting-${idx}`"
            >
                <SettingRow
                    :data="{
                        ...each
                    }"
                    @setting-changed="toggleSimpleSetting"
                />
            </div>
        </SettingsSection>
        <SettingsSection
            title="App Notifications"
            description="Select when you'll be notified when the following changes occur."
        >
            <SettingRow
                right
                :data="{
                    setting: appSettingAll,
                    destination: 'app',
                    type: ['all'],
                    display: 'Select All'
                }"
                @setting-changed="toggleSetting"
            />
            <div
                v-for="(each, idx) in settings.app" :key="`setting-app-${idx}`"
            >
                <SettingRow :data="{ ...each, destination: 'app' }" @setting-changed="toggleSetting" />
            </div>
        </SettingsSection>
    </div>
</template>

<script>
import { SettingRow, SettingsSection } from '@/components/settings/shared';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';

import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'NotificationSettings',
    mixins: [createPayload],
    components: {
        GrayBoxLoader,
        SettingRow,
        SettingsSection,
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        settings() {
            return this.$store.getters.settings;
        },
        simpleEmailSettings() {
            if (!this.$store.getters.settings.simple) {
                return null;
            }
            return this.$store.getters.settings.simple.filter((setting) => setting.emailOnly === true);
        },
        emailSettingAll() {
            if (!this.settings || !this.settings.email) {
                return null;
            }
            const settings = this.settings.email.map((each) => each.setting);
            return !settings.includes(false);
        },
        appSettingAll() {
            if (!this.settings || !this.settings.app) {
                return null;
            }
            const settings = this.settings.app.map((each) => each.setting);
            return !settings.includes(false);
        },
    },
    methods: {
        async toggleSetting(data) {
            const togglePayload = await this.createPayload({
                ...data,
            });

            const toggleNotificationPromise = this.$store.dispatch('toggleNotificationSetting', togglePayload);

            if (data.type.includes('all')) {
                const toggleSimpleSettingsPromises = this.simpleEmailSettings.map((simpleSetting) => {
                    const updatedSetting = { ...simpleSetting, setting: this.emailSettingAll };
                    return this.toggleSimpleSetting(updatedSetting);
                });

                await Promise.all([toggleNotificationPromise, ...toggleSimpleSettingsPromises]);
            } else {
                await toggleNotificationPromise;
            }
        },
        async toggleSimpleSetting(data) {
            const togglePayload = await this.createPayload({
                ...data,
            });
            await this.$store.dispatch('toggleSimpleSettings', togglePayload);
        },
    },
    async mounted() {
        const payload = await this.createPayload();
        const notificationsFetch = () => this.$store.dispatch('getNotificationSettings', payload);

        await Promise.all([
            notificationsFetch(),
        ]);
        this.loading = false;
    },
};
</script>

<style lang="stylus">
.notification-settings {
    width: 100%;
    &__loader {
        min-height: auto;
    }
}
</style>
