import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

import { displayName as getDisplayName } from '@/utils/data/display';
import {
    getAssetCategory,
    getAssetOrgId,
    randomOrder,
} from '@/utils/data/resources';
import { fetchAssetFromDE } from '@/utils/fetches';

import parseEmployee from '@/utils/data/parseEmployee';

import { educatorRoles, METADATA_ROLES, roles } from '@/constants';

import actions from '@/store/actions';
import { profileBioModal } from '@/store/getters';
import mutations from '@/store/mutations';
import stateSchema from '@/store/state';

import attachmentActions from './attachments';
import deActions from './de';
import educatorActions from './educators';
import messaging from './messaging';
import notificationActions from './notifications';
import requestActions from './requests';
import settings from './settings';

const fallBackLogo = require('@/assets/de-logo_md.png');

Vue.use(Vuex);

const API_DOMAIN = process.env.VUE_APP_API_URL;

const pageSchema = {
    employees: [],
    availableFilters: [],
    'evergreen-educator_assets': {},
    organization: {},
    organizations: [],
    employee: {},
    timeSlots: [],
    assets: {},
    related_assets: {},
    all_assets: {},
    organization_assets: {},
    recommended_assets: {},
    connectors_recommended: [],
    request: {},
    requests: [],
    applicants: [],
    applications: [],
    recommended: [],
    request_matches: [],
    direct_requested_applicant: null,
    error: null,
    upcoming: [],
    messages: null,
    settings: null,
    params: null,
};

const fetchResourceFromQueue = async (context, data) => {
    const { type, token, max } = data;
    const { state, commit, dispatch } = context;
    const hasQueue = state.app.queue[type] && state.app.queue[type].length;

    if (!hasQueue) {
        return;
    }

    const [nextFetch] = state.app.queue[type];

    commit('DEQUEUE', { value: nextFetch, key: type });
    await dispatch('getResource', {
        token,
        data: {
            assetId: nextFetch,
            type,
            max,
        },
    });
};
const store = new Vuex.Store({
    state: {
        app: {
            requests: [],
            flags: {
                'interactive-assets': true,
                video: false,
                'spotlight-banner': true,
                messaging: true,
                attachments: true,
                stats: true,
                time_filters: false,
                settings: true,
                carousel: true,
                'calendar-removed': true,
                'intro-steps': true,
                'bio-helper': true,
                'pause-direct-requests': true,
                'educator-setup-redesign': true,
                'quick-filters': true,
                'educator-upcoming-request': false,
                'digital-citizenship-spotlight': false,
                'atlas-content': false,
            },
            spotlight: {},
            mode: '',
            organization: {
                name: '',
                avatarUrl: '',
            },
            employees: [],
            error: null,
            breadcrumb: [],
            active: true,
            queue: {},
            notifications: [],
        },
        page: {
            ...pageSchema,
            pagination: {},
            params: {},
        },
        user: {
            organizations: {},
            availability: [],
            timeSlots: [],
            data: {},
            hasAdmin: false,
        },
        status: {
            user: {
                loading: false,
                error: null,
            },
            organization: {
                loading: false,
                error: null,
            },
            availability: {
                loading: false,
                error: null,
            },
            schedules: {
                loading: false,
                error: null,
            },
            invites: {
                loading: false,
                error: null,
            },
            employee_activate: {
                loading: false,
                error: null,
            },
            org_activate: {
                loading: false,
                error: null,
            },
            list_employees: {
                loading: false,
                error: null,
            },
            page: {
                loading: false,
                error: null,
            },
            app: {
                loading: false,
                error: null,
            },
            requests: {
                loading: false,
                error: null,
            },
            request_apply: {
                loading: false,
                results: null,
                error: null,
            },
            request_submit: {
                loading: false,
                results: null,
                error: null,
            },
            request_save_draft: {
                loading: false,
                results: null,
                error: null,
            },
            request_direct_submit: {
                loading: false,
                results: null,
                error: null,
            },
            request_direct_accept: {
                loading: false,
                results: null,
                error: null,
            },
            application_accept: {
                loading: false,
                results: null,
                error: null,
            },
            request_direct_decline: {
                loading: false,
                error: null,
            },
        },
        ...stateSchema,
    },
    mutations: {
        ...mutations,
        SET_ORGANIZATION(state, data) {
            state.app.organization = data;
        },
        UPDATE_ERROR(state, data) {
            state.app.error = data;
        },
        UPDATE_ORGANIZATION(state, data) {
            state.app.organization = { ...state.app.organization, ...data };
        },
        UPDATE_USER(state, data) {
            state.user = { ...state.user, ...data };
        },
        UPDATE_USER_DATA(state, data) {
            state.user.data = { ...state.user.data, ...data };
        },
        UPDATE_APP(state, data) {
            state.app = { ...state.app, ...data };
        },
        UPDATE_PAGE(state, data) {
            state.page = { ...state.page, ...data };
        },
        UPDATE_PAGE_KEY(state, data) {
            const { key, ...rest } = data;

            if (!key) {
                return;
            }
            state.page[key] = { ...state.page[key], ...rest };
        },
        UPDATE_BREADCRUMB(state, data) {
            const { link } = data;
            let { text } = data;

            if (!link) {
                return;
            }

            const breadcrumbMap = {
                'connector-profile': 'Connector Profile',
                dashboard: 'Dashboard',
                'asset-details': 'Content Details',
                'browse-connectors': 'All Connectors',
                'browse-organizations': 'Organizations',
                'connect-directory': 'Connect Directory',
                'request-details': 'Request Details',
                'request-edit': 'Edit Request',
                'browse-content': 'Browse Content',
                'browse-all': 'Browse All',
                'organization-content': 'Content',
                'direct-request': 'Direct Request',
                'user-management': 'User Management',
                message: 'Messages',
            };

            let updated = state.app.breadcrumb;
            text = breadcrumbMap[text] || text;

            const latestBreadcrumb = updated[updated.length - 1];

            const newBreadcrumb = {
                dim_text: '',
                link,
                text,
            };

            if (updated.length > 1) {
                updated = updated.slice(state.app.breadcrumb.length - 1);
            }

            // if latest breadcrumb is same display text, update to new breadcrumb
            // may have updated url params
            if (latestBreadcrumb && latestBreadcrumb.text && latestBreadcrumb.text.toLowerCase() === data.text.toLowerCase()) {
                updated[updated.length - 1] = newBreadcrumb;
                state.app.breadcrumb = updated;
                return;
            }

            // if more than one breadcrumb is already stored, cut down to the last element
            // max storage is 2
            if (updated.length > 1) {
                updated = updated.slice(state.app.breadcrumb.length - 1);
            }

            updated.push(newBreadcrumb);
            state.app.breadcrumb = updated;
        },
        RESET_BREADCRUMBS(state) {
            state.app.breadcrumb = [];
        },
        UPDATE_REQUESTS(state, data) {
            state.page.requests = state.page.requests.concat(data);
        },
        UPDATE_MODE(state, data) {
            state.app.mode = data;
        },
        SET_PAGE_DATA(state, data) {
            state.page = { ...state.page, ...data };
        },
        SET_AVAILABILITY(state, data) {
            state.user.availability = data;
        },
        SET_TIME_SLOT(state, data) {
            state.user.timeSlots.push(data);
        },
        SET_TIME_SLOTS(state, data) {
            state.user.timeSlots = [...state.user.timeSlots, ...data];
        },
        DELETE_TIME_SLOT(state, data) {
            state.user.timeSlots = state.user.timeSlots.filter((item) => item.timeSlotId !== data);
        },
        UPDATE_LOADING(state, data) {
            state.status[data.key].loading = data.status;
        },
        UPDATE_RESULTS(state, data) {
            state.status[data.key].results = data.status;
        },
        RESET_PAGE_DATA(state, data) {
            if (data) {
                state.page = { ...state.page, data };
            } else {
                state.page = pageSchema;
            }
        },
        UPDATE_FEATURE_FLAGS(state, data) {
            state.app.flags = { ...state.app.flags, ...data.flags };
        },
        REMOVE_ASSET(state, data) {
            const { type, assetId } = data;
            const { [assetId]: removed, ...rest } = state.page[type];
            state.page[type] = rest;
        },
        UPDATE_ASSET(state, data) {
            const { type, ...rest } = data;

            state.page[type] = { ...state.page[type], ...rest };
        },
        ENQUEUE(state, data) {
            const { key, value } = data;
            if (!state.app.queue[key]) {
                state.app.queue[key] = [];
            }

            if (state.app.queue[key].includes(value)) {
                return;
            }
            state.app.queue[key].push(value);
        },
        DEQUEUE(state, data) {
            const { key, value } = data;

            const match = this.state.app.queue[key].findIndex((each) => each === value);
            state.app.queue[key].splice(match, 1);
        },
    },
    actions: {
        ...deActions,
        ...educatorActions,
        ...requestActions,
        ...notificationActions,
        ...attachmentActions,
        ...messaging,
        ...settings,
        // shared for accurate testing
        ...actions,
        updateApp({ commit }, data) {
            commit('UPDATE_APP', data);
        },
        updatePage({ commit }, data) {
            if (data.key) {
                commit('UPDATE_PAGE_KEY', data);
                return;
            }
            commit('UPDATE_PAGE', data);
        },
        updateMode({ commit }, data) {
            commit('UPDATE_MODE', data);
        },
        updateUser({ commit }, data) {
            commit('UPDATE_USER', data);
        },
        updateUserData({ commit }, data) {
            commit('UPDATE_USER_DATA', data);
        },
        updateOrganization({ commit }, data) {
            commit('UPDATE_ORGANIZATION', data);
        },
        updateBreadcrumb({ commit, state }, data) {
            const currentBreadcrumbs = state.app.breadcrumb.map((each) => each.link);
            const latestBreadcrumb = currentBreadcrumbs[currentBreadcrumbs.length - 1];
            if (latestBreadcrumb !== data.link.toLowerCase()) {
                commit('UPDATE_BREADCRUMB', data);
            }
        },
        resetBreadcrumbs({ commit }) {
            commit('RESET_BREADCRUMBS');
        },
        updateFeatureFlags({ commit }, data) {
            commit('UPDATE_FEATURE_FLAGS', data);
        },
        updateError({ commit }, message) {
            commit('UPDATE_ERROR', message);
        },
        async getResource(context, payload) {
            const { assetId, subcategory } = payload.data;
            const {
                commit,
                state,
                dispatch,
                getters,
            } = context;

            const assetCategory = getAssetCategory(assetId, true);
            const associatedOrgId = getAssetOrgId(assetId);

            let { type, max } = payload.data;
            if (!type) {
                type = 'assets';
            } else if (!type.includes('assets')) {
                type = `${type}_assets`;
            }

            if (subcategory) {
                type = `${type}_${subcategory}`;
            }

            if (!max) {
                max = 4;
            }

            // dequeue if in the queue
            if (state.app.queue[type] && state.app.queue[type].includes(assetId)) {
                commit('DEQUEUE', { value: assetId, key: type });
            }

            // initialize resource category if needed
            if (!state.page[type]) {
                state.page[type] = [];
            }

            // a fetch is "pending until it comes back successfully"
            const pendingFetches = Object.keys(state.page[type]);
            if (pendingFetches.length >= max) {
                commit('ENQUEUE', { key: type, value: assetId });
                return;
            }

            commit('UPDATE_ASSET', { [assetId]: { loading: true }, type });

            try {
                const response = await fetchAssetFromDE(payload.token, assetId);

                if (response.data.asset) {
                    const { asset } = response.data;
                    // interactive type asset feature flagged
                    const interactivesVisible = getters.interactiveAssetsFF;
                    if (!interactivesVisible && asset.type.name.toLowerCase() === 'investigation') {
                        commit('REMOVE_ASSET', { assetId, type });
                        await fetchResourceFromQueue(context, {
                            type,
                            token: payload.token,
                            max,
                        });
                        return;
                    }

                    if (!asset.clean_media_files || !asset.media_files) {
                        commit('REMOVE_ASSET', { assetId, type });
                        await fetchResourceFromQueue(context, {
                            type,
                            token: payload.token,
                            max,
                        });
                        return;
                    }

                    const storedOrgs = Object.keys(state.page.organization);
                    const userOrg = state.app.organization.organizationId;
                    let org;
                    if (!assetCategory || assetCategory.includes('evergreen')) {
                        org = {
                            avatarUrl: fallBackLogo,
                            name: 'Career Connect',
                            evergreen: true,
                        };
                    } else if (associatedOrgId === userOrg) {
                        const { avatarUrl, name } = state.app.organization;
                        org = {
                            avatarUrl, name,
                        };
                    } else if (associatedOrgId !== userOrg) {
                        // if this asset is associated with another organization
                        // and that org has not been fetched
                        // fetch that data for logo and name

                        if (!storedOrgs.includes(associatedOrgId)) {
                            await dispatch('viewOrganization', {
                                token: payload.token,
                                data: {
                                    organizationId: associatedOrgId,
                                },
                            });
                        }

                        // pull the org name and logo from the fetched data
                        const { avatarUrl, name, organizationId: id } = state.page.organization[associatedOrgId];
                        org = {
                            avatarUrl, name, id,
                        };
                    }

                    commit('UPDATE_ASSET', {
                        [assetId]:
                            {
                                ...response.data.asset,
                                ...{ loading: false, org },
                            },
                        type,
                    });
                } else {
                    // remove the asset from the store if not fetched successfully
                    commit('REMOVE_ASSET', { assetId, type });
                    await fetchResourceFromQueue(context, { type, token: payload.token, max });
                }
            } catch (error) {
                console.log(error);
                console.log(payload);
                // remove the asset from the store if not fetched successfully

                commit('REMOVE_ASSET', { assetId, type });
                await fetchResourceFromQueue(context, { type, token: payload.token, max });
            }
        },
        removeAsset({ commit }, data) {
            const { assetId, type } = data;
            return commit('REMOVE_ASSET', { assetId, type });
        },
        async saveOrganization({ commit }, payload) {
            try {
                const response = await axios.post(`${API_DOMAIN}/organizations/${this.state.app.organization.organizationId}`,
                    this.state.app.organization,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`, // send the access token through the 'Authorization' header
                        },
                    });

                commit('SET_ORGANIZATION', response.data);
                // commit('SET_ORGANIZATION', organization);
            } catch (error) {
                console.log(error);
                console.log(payload);
            }
        },
        // Base GET actions for loading and storing user data globally
        async getOrganization({ commit }, payload) {
            try {
                commit('UPDATE_LOADING', { key: 'organization', status: true });
                const response = await axios.get(`${API_DOMAIN}/organizations/${payload.organizationId}`, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`, // send the access token through the 'Authorization' header
                    },
                });

                commit('SET_ORGANIZATION', response.data);
                commit('UPDATE_LOADING', { key: 'organization', status: false });
                // commit('SET_ORGANIZATION', organization);
            } catch (error) {
                console.log(error);
                console.log(payload);
                commit('UPDATE_LOADING', { key: 'organization', status: false });
            }
        },
        // VIEW actions for loading page data (viewing another organization)
        async viewOrganization({ state, commit }, payload) {
            const cachedOrgs = Object.keys(state.page.organization);
            const { organizationId } = payload.data;

            // no need to fetch orgs if already stored
            if (cachedOrgs.includes(organizationId)) {
                return;
            }

            try {
                commit('UPDATE_LOADING', { key: 'page', status: true });
                const response = await axios.get(`${API_DOMAIN}/organizations/${payload.data.organizationId}`, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`, // send the access token through the 'Authorization' header
                    },
                });

                const orgDataObj = {
                    ...state.page.organization,
                    ...{ [payload.data.organizationId]: response.data },
                };
                commit('SET_PAGE_DATA', {
                    organization: orgDataObj,
                });
                commit('UPDATE_LOADING', { key: 'page', status: false });
                // commit('SET_ORGANIZATION', organization);
            } catch (error) {
                console.log(error);
                console.log(payload);
                commit('UPDATE_LOADING', { key: 'page', status: false });
            }
        },
        // VIEW a single employee (for profile)
        async viewEmployee({ commit }, payload) {
            try {
                commit('UPDATE_LOADING', { key: 'page', status: true });
                const response = await axios.get(`${API_DOMAIN}/employees/${payload.data.employeeId}`, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                    },
                });

                commit('SET_PAGE_DATA', { employee: { data: response.data } });
                commit('UPDATE_LOADING', { key: 'page', status: false });
            } catch (error) {
                console.log(error);
                console.log(payload);
                commit('UPDATE_LOADING', { key: 'page', status: false });
            }
        },
        async viewEmployees({ commit }, payload) {
            try {
                commit('UPDATE_LOADING', { key: 'page', status: true });
                const response = await axios.get(`${API_DOMAIN}/organizations/${payload.data.organizationId}/employees`, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                    },
                });
                commit('SET_PAGE_DATA', { employees: response.data.records });
                commit('UPDATE_LOADING', { key: 'page', status: false });
            } catch (error) {
                console.log(payload);
                console.log(error);
                commit('UPDATE_LOADING', { key: 'page', status: false });
            }
        },
        async activateOrganization({ commit }, payload) {
            const setToActive = {
                ...this.state.app.organization,
                ...{
                    isActive: true,
                },
            };

            try {
                commit('UPDATE_LOADING', { key: 'org_activate', status: true });
                const response = await axios.post(`${API_DOMAIN}/organizations/${this.state.app.organization.organizationId}`,
                    setToActive,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`, // send the access token through the 'Authorization' header
                        },
                    });

                if (response.status === 200) {
                    const { isActive } = response.data;
                    commit('SET_ORGANIZATION', response.data);
                    commit('UPDATE_APP', { active: isActive });
                }
                commit('UPDATE_LOADING', { key: 'org_activate', status: false });
            } catch (error) {
                console.log(error);
                console.log(payload);
                commit('UPDATE_LOADING', { key: 'org_activate', status: false });
            }
        },
        async createOrganizationEmployees({ commit }, payload) {
            try {
                commit('UPDATE_LOADING', { key: 'invites', status: true });
                const response = await axios.post(`${API_DOMAIN}/organizations/${this.state.app.organization.organizationId}/employees`,
                    payload.data,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                        },
                    });
                if (response.data.organizationId) {
                    commit('UPDATE_ORGANIZATION', response.data);
                }
                commit('UPDATE_LOADING', { key: 'invites', status: false });
            } catch (error) {
                console.log(payload);
                console.log(error);
                commit('UPDATE_LOADING', { key: 'invites', status: false });
            }
        },
        async updateOrganizationEmployee({ commit }, payload) {
            const { employeeId, ...rest } = payload.data;
            if (!employeeId) {
                return;
            }
            try {
                commit('UPDATE_LOADING', { key: 'list_employees', status: true });
                const response = await axios.post(`${API_DOMAIN}/organizations/${this.state.app.organization.organizationId}/employees/${employeeId}`,
                    rest,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                        },
                    });
                if (response.data[0]) {
                    commit('UPDATE_APP', { employees: response.data });
                }
                commit('UPDATE_LOADING', { key: 'list_employees', status: false });
            } catch (error) {
                console.log(payload);
                console.log(error);
                commit('UPDATE_LOADING', { key: 'list_employees', status: false });
            }
        },
        async removeEmployee({ commit }, payload) {
            try {
                const response = await
                axios.delete(`${API_DOMAIN}/organizations/${this.state.app.organization.organizationId}/employees/${payload.data.employeeId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                        },
                    });
                commit('UPDATE_ORGANIZATION', response.data);
            } catch (error) {
                console.log(payload);
                console.log(error);
            }
        },
        async createAvailability({ commit }, payload) {
            try {
                commit('UPDATE_LOADING', { key: 'availability', status: true });
                const response = await axios.post(`${API_DOMAIN}/employees/${this.state.user.employeeId}/availability`,
                    payload.data,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                        },
                    });
                commit('SET_AVAILABILITY', response.data);
                commit('UPDATE_LOADING', { key: 'availability', status: false });
            } catch (error) {
                console.log(error);
                commit('UPDATE_LOADING', { key: 'availability', status: false });
            }
        },
        async getAvailability({ commit }, payload) {
            try {
                commit('UPDATE_LOADING', { key: 'availability', status: true });
                const request = await axios.get(`${API_DOMAIN}/employees/${this.state.user.employeeId}/availability`, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                    },
                });
                commit('SET_AVAILABILITY', request.data);
                commit('UPDATE_LOADING', { key: 'availability', status: false });
            } catch (error) {
                console.log(error);
                commit('UPDATE_LOADING', { key: 'availability', status: false });
            }
        },
        async createTimeSlot({ commit }, payload) {
            try {
                const data = await axios.post(`${API_DOMAIN}/employees/${this.state.user.employeeId}/schedule`,
                    payload.data,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                        },
                    });
                commit('SET_TIME_SLOT', data);
            } catch (error) {
                console.log(error);
            }
        },
        async getEmployeeMatches({ commit }, payload) {
            try {
                const response = await axios.get(`${API_DOMAIN}/educators/${payload.data.educatorId}/requests/${payload.data.requestId}/matches`, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                    },
                });
                commit('SET_EMPLOYEE_MATCHES', { requestId: payload.data.requestId, matches: response.data });
            } catch (error) {
                console.log(payload);
                console.log(error);
            }
        },
        async getTimeSlots({ commit }, payload) {
            let { employeeId } = this.state.user;
            const { start, end } = payload.data;
            let mutation = 'SET_TIME_SLOTS';
            let mutationKey;

            // if employeeId is included in the payload, consider this a page view (not a self view)
            if (payload.data.employeeId) {
                employeeId = payload.data.employeeId;
                mutation = 'SET_PAGE_DATA';
                mutationKey = 'time_slots';
            }

            try {
                const response = await axios.get(
                    `${API_DOMAIN}/employees/${employeeId}/schedule?start=${start}&end=${end}`,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                        },
                    },
                );
                if (mutationKey) {
                    commit(mutation, { [mutationKey]: response.data });
                } else {
                    commit(mutation, response.data);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async deleteTimeSlot({ commit }, payload) {
            try {
                await axios.delete(
                    `${API_DOMAIN}/employees/${this.state.user.employeeId}/schedule/${payload.data}`,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                        },
                    },
                );
                commit('DELETE_TIME_SLOT', payload.data);
            } catch (error) {
                console.log(error);
            }
        },
        async getEmployee({ commit }, payload) {
            try {
                commit('UPDATE_LOADING', { key: 'user', status: true });
                const response = await axios.get(`${API_DOMAIN}/employees/${this.state.user.employeeId}`, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                    },
                });

                const { data, organizations } = parseEmployee(response.data, this.state.user);

                commit('UPDATE_USER', { data, organizations });

                commit('UPDATE_ERROR', null);
                commit('UPDATE_LOADING', { key: 'user', status: false });
            } catch (error) {
                commit('UPDATE_ERROR', 'We\'re currently unable to fetch your user data. Please try again later.');
                commit('UPDATE_LOADING', { key: 'user', status: false });
            }
        },
        async saveEmployee({ commit }, payload) {
            try {
                commit('UPDATE_LOADING', { key: 'user', status: true });
                const response = await axios.post(`${API_DOMAIN}/employees/${this.state.user.employeeId}`,
                    this.state.user.data,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`, // send the access token through the 'Authorization' header
                        },
                    });

                const { data, organizations } = parseEmployee(response.data, this.state.user);

                commit('UPDATE_USER', { data, organizations });
                commit('UPDATE_LOADING', { key: 'user', status: false });
            } catch (error) {
                console.log(error);
                console.log(payload);
                commit('UPDATE_LOADING', { key: 'user', status: false });
            }
        },
        async activateEmployee({ commit }, payload) {
            const setToActive = {
                ...this.state.user.data,
                ...{
                    isActive: true,
                },
            };

            try {
                commit('UPDATE_LOADING', { key: 'employee_activate', status: true });
                const response = await axios.post(`${API_DOMAIN}/employees/${this.state.user.employeeId}`,
                    setToActive,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`, // send the access token through the 'Authorization' header
                        },
                    });

                if (response.status === 200) {
                    const { data, organizations } = parseEmployee(response.data, this.state.user);
                    const { isActive } = data;

                    commit('UPDATE_USER', { data, organizations });
                    commit('UPDATE_APP', { active: isActive });
                }
                commit('UPDATE_LOADING', { key: 'employee_activate', status: false });
            } catch (error) {
                console.log(error);
                console.log(payload);
                commit('UPDATE_LOADING', { key: 'employee_activate', status: false });
            }
        },
        updatePagination({ commit }, data) {
            let page;
            const recordsLength = data.records ? data.records.length : data.recordsLength;
            const {
                record_count: recordCount,
                limit,
                offset,
            } = data;

            if (offset === 0) {
                page = 1;
            } else {
                page = Math.floor(offset / limit) + 1;
            }

            commit('UPDATE_PAGE', {
                pagination: {
                    page,
                    recordCount,
                    limit,
                    offset,
                    pageCount: recordsLength,
                },
            });
        },
        // this will be used for searching/filtering
        async getEmployees({ commit, dispatch }, payload) {
            commit('UPDATE_LOADING', { key: 'page', status: true });
            const params = payload.data && payload.data.params ? `?${payload.data.params}` : '';
            try {
                const response = await axios.get(`${API_DOMAIN}/employees${params}`, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                    },
                });

                // eslint-disable-next-line camelcase
                const { records, available_filters } = response.data;
                dispatch('updatePagination', response.data);
                commit('UPDATE_PAGE', { employees: records, availableFilters: available_filters });
                commit('UPDATE_LOADING', { key: 'page', status: false });
            } catch (error) {
                console.log(payload);
                console.log(error);
                commit('UPDATE_LOADING', { key: 'page', status: false });
            }
        },
        async getOrganizationEmployees({ commit }, payload) {
            // if there's an orgId in the payload, assume it's a page view
            // otherwise, use the globally stored id, because it's a self view
            const isPageView = Boolean(payload.data && payload.data.organizationId);
            const organizationId = isPageView ? payload.data.organizationId : this.state.app.organization.organizationId;

            try {
                if (isPageView) {
                    commit('UPDATE_LOADING', { key: 'page', status: true });
                } else {
                    commit('UPDATE_LOADING', { key: 'organization', status: true });
                }
                const response = await axios.get(`${API_DOMAIN}/organizations/${organizationId}/employees`, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`, // send the access token through the 'Authorization' header
                    },
                });

                let toStore = response.data.records;
                if (response.data.length > 1 && response.data[0].avatar_url) {
                    toStore = response.data.map((each) => {
                        const avatarUrl = each.avatar_url;
                        return {
                            ...each,
                            ...{
                                avatarUrl,
                            },
                        };
                    });
                }

                if (isPageView) {
                    commit('SET_PAGE_DATA', { employees: toStore });
                    commit('UPDATE_LOADING', { key: 'page', status: false });
                } else {
                    commit('UPDATE_APP', { employees: toStore });
                    commit('UPDATE_LOADING', { key: 'organization', status: false });
                }
                // commit('UPDATE_APP', employees);
            } catch (error) {
                console.log(payload);
                console.log(error);
                if (isPageView) {
                    commit('UPDATE_LOADING', { key: 'page', status: false });
                } else {
                    commit('UPDATE_LOADING', { key: 'organization', status: false });
                }
            }
        },
        async getOrganizations({ commit, dispatch, state }, payload) {
            const { max } = payload.data;
            const params = payload.data && payload.data.params ? `?${payload.data.params}` : '';
            try {
                const response = await axios.get(`${API_DOMAIN}/organizations${params}`, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`, // send the access token through the 'Authorization' header
                    },
                });
                dispatch('updatePagination', response.data);

                let organizations = response.data.records;

                // sometimes API returns an Array with a length of 1 but null contents
                // handle that here
                if (!organizations.find((each) => each.organizationId)) {
                    organizations = [];
                    commit('UPDATE_PAGE', { organizations });
                    return;
                }

                if (state.app.spotlight) {
                    // if the current spotlight is an org, pull it out to the front
                    const idx = organizations.findIndex((org) => org.organizationId === state.app.spotlight.id);
                    const [highlighted] = organizations.splice(idx, 1);

                    organizations = randomOrder(organizations);
                    organizations.unshift(highlighted);
                } else {
                    organizations = randomOrder(organizations);
                }

                if (max && organizations.length > max) {
                    organizations = organizations.slice(0, max);
                }

                commit('UPDATE_PAGE', { organizations });
            } catch (error) {
                console.log(payload);
                console.log(error);
            }
        },

        async updateEmployeeAvatar({ commit }, payload) {
            try {
                const response = await axios.post(`${API_DOMAIN}/employees/${this.state.user.employeeId}/update-avatar`,
                    payload.data.fileContent,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                const { data, organizations } = parseEmployee(response.data, this.state.user);
                commit('UPDATE_USER', { data, organizations });
            } catch (error) {
                console.log(error);
                console.log(payload);
            }
        },

        async updateOrgAvatar({ commit }, payload) {
            try {
                const response = await axios.post(`${API_DOMAIN}/organizations/${this.state.app.organization.organizationId}/update-avatar`,
                    payload.data.fileContent,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                commit('UPDATE_ORGANIZATION', response.data);
            } catch (error) {
                console.log(error);
                console.log(payload);
            }
        },
        updateLoading({ commit }, payload) {
            commit('UPDATE_LOADING', { key: payload.key, status: payload.status });
        },
        updateResults({ commit }, payload) {
            commit('UPDATE_RESULTS', { key: payload.key, status: payload.status });
        },
        async resetPageData({ commit }, payload) {
            if (payload && payload.key) {
                return commit('RESET_PAGE_DATA', pageSchema[payload.key]);
            }
            return commit('RESET_PAGE_DATA');
        },
    },
    getters: {
        profileBioModal,
        appMode(state) {
            return state.app.mode;
        },
        appError(state) {
            return state.app.error;
        },
        request(state) {
            return state.page.request;
        },
        requestAttachments(state) {
            return state.page.request.attachments;
        },
        // MODALS
        avatarUploadModal(state) {
            return state.modals.settings.upload_image;
        },
        removeUserModal(state) {
            return state.modals.user_management.remove;
        },
        requestApplyModal(state) {
            return state.modals.request.apply;
        },
        requestCancelModal(state) {
            return state.modals.request.cancel;
        },
        requestApproveModal(state) {
            return state.modals.request.approve;
        },
        requestDeclineModal(state) {
            return state.modals.request.decline;
        },
        requestDeclineDirectModal(state) {
            return state.modals.request['decline-direct'];
        },
        requestRescheduleModal(state) {
            return state.modals.request.reschedule;
        },
        requestEditModal(state) {
            return state.modals.request.edit;
        },
        // **
        employeeToRemove(state) {
            return state.modals.user_management.remove.employeeId;
        },
        getOrganization(state) {
            return state.app.organization;
        },
        getMode(state) {
            return state.app.mode;
        },
        organizationId(state) {
            return state.app.organization.organizationId;
        },
        userId(state) {
            if (educatorRoles.includes(state.app.mode)) {
                return state.user.data.id;
            }
            return state.user.data.employeeId;
        },
        userName(state) {
            return state.user.data.email;
        },
        userData(state) {
            return state.user.data;
        },
        userDisplayData(state) {
            const {
                firstName,
                lastName,
                preferredName,
                avatarUrl,
            } = state.user.data;

            const displayName = getDisplayName({
                firstName, lastName, preferredName,
            });

            return {
                displayName,
                avatarUrl,
            };
        },
        userOrganizations(state) {
            return state.user.organizations;
        },
        adminOrganizations(state, getters) {
            const organizations = getters.userOrganizations;
            const adminRole = METADATA_ROLES[roles.ORGADMIN_ROLE];

            return organizations.filter((org) => org.roles.includes(adminRole));
        },
        // PAGE DATA
        profileEmployeeOrgs(state) {
            if (!state.page.employee.data) {
                return [];
            }
            return state.page.employee.data.organizations;
        },
        viewEmployee(state) {
            return state.page.employee.data;
        },
        applyResult(state) {
            return state.status.request_apply.results;
        },
        acceptDirectRequestResult(state) {
            return state.status.request_direct_accept.results;
        },
        applicationAccept(state) {
            return state.status.application_accept.results;
        },
        settings(state) {
            return state.page.settings;
        },
        params(state) {
            return state.page.params;
        },
        // FEATURE FLAG GETTERS
        flags(state) {
            return state.app.flags;
        },
        interactiveAssetsFF(state) {
            return state.app.flags['interactive-assets'];
        },
        videoFF(state) {
            return state.app.flags.video;
        },
        messagingFF(state) {
            return state.app.flags.messaging;
        },
    },
});

export default store;
