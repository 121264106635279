<template>
    <NebulaCard
        element="li"
        class="nebula-card-carousel__list-item profile-card"
    >
        <template v-slot:thumbnail>
            <ProfileAvatar
                class="profile-card__avatar"
                :image="data.avatarUrl"
                size="lg"
                :border="false"
            />
        </template>
        <template v-slot:content>
            <div class="profile-card__body">
                <NebulaCardTitle
                    class="profile-card__title"
                    tabIndex="-1"
                    :title="displayName"
                    :url="profileLink"
                />
                <NebulaCardDescription
                    class="profile-card__description"
                >
                    <span class="profile-card__position">{{ data.title }}</span>
                    <span class="profile-card__location">{{ `${data.city}, ${data.state}` }}</span>
                </NebulaCardDescription>
                <div
                    class="profile-card__organization-wrapper"
                >
                    <div
                        class="profile-card__organization"
                        v-for="(organization, idx) in data.organizations"
                        :key="`organization-${idx}`"
                    >
                        <ProfileAvatar
                            class="profile-card__organization-avatar"
                            :image="organization.avatarUrl"
                            size="xs"
                            :border="true"
                        />
                        <OrgName :organization="organization" heavy />
                    </div>
                </div>
            </div>
            <div class="profile-card__footer">
                <slot name="actions">
                    <NebulaButton
                        :text="$t('View Profile')"
                        :aria-label="$t('View Profile')"
                        class="profile-card__button"
                        :routerLink="isRouterLink"
                        size="l"
                        type="ghost"
                        :link="profileLink"
                    />
                </slot>
            </div>
        </template>
    </NebulaCard>
</template>

<script>
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';
import OrgName from '@/components/shared/tokens/OrgName.vue';
import { getAtlasPageIdFromOrgId } from '@/utils/data/resources';
import {
    NebulaButton, NebulaCard, NebulaCardDescription, NebulaCardTitle,
} from '@discoveryedu/nebula-components';

export default {
    name: 'CardProfile',
    components: {
        NebulaButton,
        NebulaCard,
        NebulaCardTitle,
        NebulaCardDescription,
        ProfileAvatar,
        OrgName,
    },
    computed: {
        displayFirstName() {
            const preferredName = this.data.preferred_name || this.data.preferredName;
            const { firstName } = this.data;
            return preferredName || firstName;
        },
        lastName() {
            return this.data.lastName;
        },
        displayName() {
            if (this.data.name) {
                return this.data.name;
            }
            return `${this.displayFirstName || ''} ${this.lastName || ''}`;
        },
        profileLink() {
            if (this.data.employeeId) {
                return `/connector/${this.data.employeeId}`;
            }
            const atlasPageId = getAtlasPageIdFromOrgId(this.data.organizationId);
            if (atlasPageId && this.$store.state.app.flags['atlas-content']) {
                return `https://careerconnect.discoveryeducation.com/pages/${atlasPageId}`;
            }
            return `/organization/${this.data.organizationId}`;
        },
        isRouterLink() {
            const atlasPageId = getAtlasPageIdFromOrgId(this.data.organizationId);
            if (atlasPageId && this.$store.state.app.flags['atlas-content']) {
                return false;
            }
            return true;
        },
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        profile: {
            type: Object,
            avatarUrl: {
                type: String,
            },
            preferredName: {
                type: String,
            },
            lastName: {
                type: String,
            },
            position: {
                type: String,
            },
            url: {
                type: String,
            },
        },
    },
    methods: {
        getOrgId(org) {
            return org.organizationId || org.organization_id;
        },
    },
};
</script>

<style lang='stylus'>
.profile-card {
    --nebula-card-background-color: $nebula-color-white;
    --nebula-card-border-color: unset;
    --nebula-card-border-radius: $nebula-border-radius-small;
    --nebula-card-border-width: 0;
    --nebula-card-box-shadow: $nebula-shadow-100;
    --nebula-card-content-padding: 0;
    --nebula-card-margin: 0;

    padding: $nebula-space-3x;
    height: 100%;

    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
        width: 100%;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            flex-wrap: wrap;
        }
    }

    &__avatar {
        flex: 0 0 auto;
        justify-content: center;
        margin: 0 auto $nebula-space-2x;
    }

    &__body {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0;
        text-align: center;
    }

    &__footer {
        display: flex;
        padding-top: $nebula-space-2x;
        gap: $nebula-space-2x;
    }

    &__title {
        color: $nebula-color-platform-interactive-800;
        font-size: $nebula-font-size-body-2;
        font-weight: normal;
        margin: 0;

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &__description {
        line-height: 1;
        margin-top: $nebula-space-half;
    }

    &__position,
    &__location {
        display: block;
        font-size: $nebula-font-size-caption;
    }

    &__position {
        color: $nebula-color-platform-neutral-900;
        font-weight: bold;
    }

    &__location {
        color: $nebula-color-platform-neutral-700;
        margin-top: $nebula-space-half;
    }

    &__organization-wrapper {
        display: flex;
        flex-direction: column;
        gap: $nebula-space-1x;
        margin: $nebula-space-2x 0 0;
    }

    &__organization {
        align-items: center;
        display: flex;
        gap: $nebula-space-1x
        justify-content: center;
    }

    &__button {
        justify-content: center;
        width: 100%;
    }

    /* Nebula */

    .nebula-card__content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}
</style>
