<template>
    <div class="filter-title">
        <div class="filter-title__text">
            <NebulaIcon
                symbol-id="options"
                class="filter-title__icon"
            />
            Filter
            <NebulaBadge
                color="orange"
                :text="numberOfFilters.toString()"
            />
        </div>
        <NebulaButton class="filter-title__link" text="Clear All" type="flat" size="s" @click="clearAll" />
    </div>
</template>

<script>
import { NebulaIcon, NebulaButton, NebulaBadge } from '@discoveryedu/nebula-components';

export default {
    name: 'FilterTitle',
    components: {
        NebulaButton,
        NebulaIcon,
        NebulaBadge,
    },
    props: {
        numberOfFilters: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        clearAll() {
            this.$emit('clear-all-clicked');
        },
    },
};
</script>

<style lang="stylus">
    .filter-title {
        align-items: center;
        justify-content: space-between;
        color: $nebula-color-platform-interface-900;
        display: flex;
        font-size: $nebula-font-size-card-title;
        font-weight: 700;
        margin-block-end: $nebula-space-3x;
        &__icon {
            fill: $nebula-color-platform-interface-900;
        }
        &__text {
            align-items: center;
            display: flex;
            gap: $nebula-space-1x;
        }
        &__link {
            link();
            border-color: inherit;
            border-radius: 0;
            padding: 0;

            &:hover {
                background: inherit;
            }
        }
    }
</style>
