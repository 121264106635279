import { roles } from '@/constants';
import store from '@/store';

const introSteps = {
    [roles.EMPLOYEE_ROLE]: {
        header: 'How Career Connect Virtual Classroom Visits Work',
        steps: [
            {
                name: 'Review',
                description: 'open requests from educators to have you visit their classroom remotely.',
            },
            {
                name: 'Apply',
                description: 'to a request and the educator will review your profile.',
            },
            {
                name: 'Confirm',
                description: 'your request was accepted via email or messages.',
            },
        ],
        actionButtonText: 'Watch the Getting Started Guide',
        actionButtonLink: store.state.app.flags['atlas-content']
            ? 'https://careerconnect.discoveryeducation.com/player/c80977f5-efb5-4ac5-b27a-179bbf0c1ed5'
            : '/asset/c80977f5-efb5-4ac5-b27a-179bbf0c1ed5',
        actionButtonRouterLink: !store.state.app.flags['atlas-content'],
    },
    [roles.EDUCATOR_ROLE]: {
        header: 'How Career Connect Works',
        steps: [
            {
                name: 'Create',
                description: 'a request to have a guest speaker visit your class virtually.',
            },
            {
                name: 'Send',
                description: 'a request to an individual or to the entire community.',
            },
            {
                name: 'Approve',
                description: 'final guest speaker.',
                note: '(Notifications via email, correspondence via website)',
            },
        ],
        actionButtonText: 'Watch the Getting Started Guide',
        actionButtonLink: store.state.app.flags['atlas-content']
            ? 'https://careerconnect.discoveryeducation.com/player/66946562-a0af-452c-8287-d26c0318408c'
            : '/asset/66946562-a0af-452c-8287-d26c0318408c',
        actionButtonRouterLink: !store.state.app.flags['atlas-content'],
    },
};

const introMessage = {
    [roles.EMPLOYEE_ROLE]: {
        title: 'Getting Started',
        description: 'Career Connect is lucky to have you part of the community! Below are the quick steps to get started.',
    },
    [roles.EDUCATOR_ROLE]: {
        title: 'Let’s get started connecting your students with real-world career professionals.',
        description:
            `Career Connect is a resource you can use to engage your class with timely topics and
            conversations related to current areas of study and beyond.`,
    },
};

export {
    introMessage, introSteps,
};
